import { HamburgerIcon,CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { Box,Image,List,ListIcon,ListItem,Text } from '@chakra-ui/react'
import React from 'react'
import {MdCheckCircle, MdRadioButtonUnchecked} from 'react-icons/md'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import img1 from '../../../assets/img-1.png'
import img2 from '../../../assets/img-2.png'
import img3 from '../../../assets/img-3.png'
import img4 from '../../../assets/img-4.png'
function Roadmap() {
  return (
    <Box id='roadmap' mt={['10','10','5']} pl={[5,5,'']} pr={[5,5,'']}>
        <Text variant={'secondSectionHeader'} textAlign='center' mb='5' >SAMOWOLF ROADMAP</Text>
        <VerticalTimeline>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: '#422493', color: '#fff' }}
    contentArrowStyle={{ borderRight: '7px solid  #422493' }}
    date="2024 --- PRESENT"
    iconStyle={{ background: '#422493', color: '#fff' }}
    icon={<Image src={img1} /> }
  >
    <h3 className="vertical-timeline-element-title">PROJECT BUILDING AND TOKEN LAUNCH</h3>
    <List spacing={1}>
        <ListItem display='flex' alignItems='center'>
            <ListIcon  as={MdCheckCircle} color='green.500' />
            <strike>Twitter Launch</strike>
        </ListItem>
        <ListItem display='flex' alignItems='center'>
            <ListIcon  as={MdCheckCircle} color='green.500' />
            <strike>Discord Launch</strike>
        </ListItem>
        <ListItem display='flex' alignItems='center'>
            <ListIcon  as={MdCheckCircle} color='green.500' />
            <strike>Website Launch</strike>
        </ListItem>
        <ListItem display='flex' alignItems='center'>
            <ListIcon  as={MdCheckCircle} color='green.500' />
            <strike>Token Creation</strike>
        </ListItem>
        <ListItem display='flex' alignItems='center'>
            <ListIcon  as={MdRadioButtonUnchecked} color='red.500' />
            TOKEN FIARLAUNCH
        </ListItem>
        <ListItem display='flex' alignItems='center'>
            <ListIcon  as={MdRadioButtonUnchecked} color='red.500' />
            NFT LISTING
        </ListItem>
        <ListItem display='flex' alignItems='center'>
            <ListIcon  as={MdRadioButtonUnchecked} color='red.500' />
            TOKEN LISTING
        </ListItem>
    </List>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: '#422493', color: '#fff' }}
    contentArrowStyle={{ borderRight: '7px solid  #422493' }}
    date="Q4 2024"  
    iconStyle={{ background: '#422493', color: '#fff' }}
    icon={<Image src={img2} /> }
  >
    <h3 className="vertical-timeline-element-title">PROJECT BUILDING AND TOKEN LAUNCH</h3>
    <List spacing={1}>
        {/* <ListItem display='flex' alignItems='center'> 
            <ListIcon  as={MdRadioButtonUnchecked} color='red.500' />
            BURN 1,000,000,000 SAMOWOLF
        </ListItem> */}
        
        <ListItem display='flex' alignItems='center'>
            <ListIcon  as={MdRadioButtonUnchecked} color='red.500' />
            COINGECKO LISTING
        </ListItem>
        <ListItem display='flex' alignItems='center'>
            <ListIcon  as={MdRadioButtonUnchecked} color='red.500' />
            COIN MARKET LISTING
        </ListItem>
        <ListItem display='flex' alignItems='center'>
            <ListIcon  as={MdRadioButtonUnchecked} color='red.500' />
            CEX LAUNCH
        </ListItem>
        <ListItem display='flex' alignItems='center'>
            <ListIcon  as={MdRadioButtonUnchecked} color='red.500' />
            LAUNCH RAFFLE GAME
        </ListItem>
        <ListItem display='flex' alignItems='center'>
            <ListIcon  as={MdRadioButtonUnchecked} color='red.500' />
            COLLABORATIONS AND VARIOUS PROJECTS
        </ListItem>

    </List>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: '#422493', color: '#fff' }}
    contentArrowStyle={{ borderRight: '7px solid  #422493' }}
    date="Q1 2025"
    iconStyle={{ background: '#422493', color: '#fff' }}
    icon={<Image src={img3} /> }
  >
    <h3 className="vertical-timeline-element-title">PROJECT BUILDING AND TOKEN LAUNCH</h3>
    <List spacing={1}>
        {/* <ListItem display='flex' alignItems='center'>
            <ListIcon  as={MdRadioButtonUnchecked} color='red.500' />
            BURN 500,000,000 SAMOWOLF
        </ListItem>
         */}
        <ListItem display='flex' alignItems='center'>
            <ListIcon  as={MdRadioButtonUnchecked} color='red.500' />
            QUIZ GAME LAUNCHING
        </ListItem>
        <ListItem display='flex' alignItems='center'>
            <ListIcon  as={MdRadioButtonUnchecked} color='red.500' />
            COMMUNITY PROMOTIONS AND BUILDING
        </ListItem>
        <ListItem display='flex' alignItems='center'>
            <ListIcon  as={MdRadioButtonUnchecked} color='red.500' />
            GIVEAWAYS
        </ListItem>
    </List>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: '#422493', color: '#fff' }}
    contentArrowStyle={{ borderRight: '7px solid  #422493' }}
    date="Q2 2025"
    iconStyle={{ background: '#422493', color: '#fff' }}
    icon={<Image src={img4} /> }
  >
    <h3 className="vertical-timeline-element-title">PROJECT BUILDING AND TOKEN LAUNCH</h3>
    <List spacing={1}>
        {/* <ListItem display='flex' alignItems='center'>
            <ListIcon  as={MdRadioButtonUnchecked} color='red.500' />
            BURN 100,000,000 SAMOWOLF
        </ListItem> */}
        
        
        <ListItem display='flex' alignItems='center'>
            <ListIcon  as={MdRadioButtonUnchecked} color='red.500' />
            LAUNCHING OF NFT STAKING POOL
        </ListItem>
        <ListItem display='flex' alignItems='center'>
            <ListIcon  as={MdRadioButtonUnchecked} color='red.500' />
            GIVEAWAYS
        </ListItem>
        <ListItem display='flex' alignItems='center'>
            <ListIcon  as={MdRadioButtonUnchecked} color='red.500' />
            PROMOTIONS
        </ListItem>
    </List>
  </VerticalTimelineElement>
 
  <VerticalTimelineElement
    iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
    icon={<Image src={img1} />}
  />
</VerticalTimeline>
    </Box>
  )
}

export default Roadmap