import { Box, Flex, Link, Text } from '@chakra-ui/react'
import React from 'react'
import {FaDiscord, FaTwitter,FaTelegram} from 'react-icons/fa'

function Footer() {
  return (
    <Box h='50vh' w='100%' pl={['5','5','10']} pr={['5','5','10']}  display='flex' flexDir='column' alignItems='center' justifyContent='center' gap='20'>
        <Text  textAlign='center'>JOIN OUR SOCIAL MEDIA CHANNELS FOR MORE INFO</Text>
        <Flex alignItems='center' gap='10' justifyContent='center'>
        <Link href='https://twitter.com/wolf_samo'><FaTwitter color='#fff' size='1.5em'/></Link>
        <Link href='https://t.me/samo_wolf'><FaTelegram color='#fff' size='1.5em'/></Link>
        <Link href='https://discord.com/invite/JX69ctTYD3'><FaDiscord color='#fff' size='1.5em'/></Link>
        </Flex>
    </Box>

  )
}

export default Footer